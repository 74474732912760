import React from 'react';
import { DeleteOutlined } from "@ant-design/icons";
import { Row, Col, Button, Card, Avatar, Dropdown, Table, Menu, Tag, Input,Image, message as gmessage, Popconfirm } from 'antd';
import loanimage from '../images/loanimage.jpeg'
import loan from '../images/i.jpg'
import {Link} from 'react-router-dom'

const Footer = () => {
    return (
        <div>
            <div>
                <Card style={{backgroundColor:'black',color:'white'}}>
                    <Row justify="center" style={{ marginTop: "5px" }}>
                        <Col style={{margin:'10px',width:'300px'}}><h2 style={{fontSize:'40px',color:'white',}}>
                            MAPHARY FINANCIALS
                            </h2>
                            
                            </Col>
                        <Col style={{margin:'10px',width:'300px'}}><h2 style={{fontSize:'20px',color:'white',}}>
                            Legal
                            </h2>
                            <hr/>
                            <h6>Terms of Use</h6>
                            <h6>Privacy Policy</h6>
                            <h6>Lender Agreement</h6>
                            <h6>Borrower Agreement</h6>
                            <h6>Guarantor's Agreement</h6>
                            </Col>
                        <Col style={{margin:'10px',width:'300px'}}><h2 style={{fontSize:'20px',color:'white',}}>
                            Company</h2><hr/>
                            <h6><Link to="/about" style={{textDecoration:'none',color:'inherit'}}>Who We are</Link></h6>
                            <h6>Contact Us</h6>
                            <h6>Lender Agreement</h6>
                            <h6>Borrower Agreement</h6>
                            <h6>Guarantor's Agreement</h6>
                            </Col>
                        <Col style={{margin:'10px',width:'300px'}}><h2 style={{fontSize:'20px',color:'white',}}>Contact Us</h2><hr/>
                            <h6>132, Herbert Macualey Way, Ebute Meta, Lagos</h6>
                            <h6>info@mapharyfinancials.net</h6>
                            <h6>Hr@mapharyfinancials.net</h6>
                            <h6>08087402779</h6>
                        </Col>
                    </Row>
                </Card>
            </div>
                 
        </div>
    );
}

export default Footer;
