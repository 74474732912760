import React from 'react';
import { Row, Col, Button, Card, Avatar, Dropdown, Table, Menu, Tag, Input,Image, message as gmessage, Popconfirm } from 'antd';
import vision from '../images/vision.png'
import mission from '../images/mission.svg'
import value from '../images/value.jpeg'
import focus from '../images/focus.jpg'
import service from '../images/service.jpg'
import Footer from './footer';

const Service = () => {
    return (
        <div>
            <div><Image src={service} alt='about banner'/></div>
            <div>
                <div style={{marginTop:'10px',padding:'10px'}}>
                    <div><h1 style={{textAlign:'center'}}>What We Do Best</h1>
                    <center>
                        <hr style={{width:'400px',alignItems:'center'}}/>
                        <hr style={{width:'300px',alignItems:'center'}}/>
                        <hr style={{width:'200px',alignItems:'center'}}/>
                    </center>
                    </div>
                    
                    <div style={{textAlign:'center',fontSize:'20px'}}>Maphary Financials is a financial service provider, offers and provide world - class financial services</div>
                </div>
                <Card>



                    <Row justify="center" style={{ marginTop: "5px",backgroundColor:'#EFF9F0',padding:'10px' }}> 
                    <Col xs={24} sm={24} md={24} lg={12}>
                            <div className='' style={{justifyContent:'center',display:'flex',alignItems:'center', padding:'30px',lineHeight:'160px'}}>
                               
                                    <div style={{fontSize:'20px',color:'green',textAlign:'center',}}>
                                      
                                        <h4 style={{lineHeight:'40px'}}>We offer competitive loan facility to individual, Micro, Small and Medium Enterprises (MSME's), LPO Financing, Travel Loan, Asset Financing, and Invoice Discounting for thier Business expansion, Equipment Leasing, Project Financing and Management, Financial Advisory Services, Consumer Loans and Salary Advance for the salary earners</h4>
                                    </div>
                                
                            </div>
                        </Col>
                    </Row>
                   
                </Card>
            </div>

            <Footer/>
                 
        </div>
    );
}

export default Service;
