import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../images/logo.jpg'
import {Link} from 'react-router-dom'
import {Avatar} from 'antd'

function NavBar() {
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="#home">
        <Avatar
            src={logo}
            size={50}
        />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            
            <Nav.Link><Link to="/about"style={{textDecoration:'none',color:'inherit'}}>About Us</Link></Nav.Link>
            <Nav.Link><Link to="/service"style={{textDecoration:'none',color:'inherit'}}>Our Service</Link></Nav.Link>
            {/* <NavDropdown title="Our Services" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#construction/3.1">Personal Loan</NavDropdown.Item>
              <NavDropdown.Item href="#construction/3.2">
                SME Loan
              </NavDropdown.Item>
              <NavDropdown.Item href="#construction/3.3">LPO Financing</NavDropdown.Item>
              <NavDropdown.Item href="#construction/3.3">Traveling Loan</NavDropdown.Item>
              <NavDropdown.Item href="#construction/3.3">Invoice Discounting</NavDropdown.Item>
              <NavDropdown.Divider />
      
            </NavDropdown> */}
          </Nav>
          <Nav>
            <Nav.Link href="#signup">Sign Up</Nav.Link>
            <Nav.Link eventKey={2} href="#signin">
              Sign In
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;