import React from 'react';
import { DeleteOutlined } from "@ant-design/icons";
import { Row, Col, Button, Card, Avatar, Dropdown, Table, Menu, Tag, Input,Image, message as gmessage, Popconfirm } from 'antd';
import Slider from './slider';
import loanimage from '../images/loanimage.jpeg'
import loan from '../images/i.jpg'
import Footer from './footer';

const Index = () => {
    return (
        <div>
            <div><Slider/></div>
            <div>
                <Card>
                <Row justify="center" style={{ marginTop: "5px" }}>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <div className='first-line-frontpage'>
                            <div>
                            <h2>Your one-stop Company for your financial needs. </h2>
                            <h2>Gain access to instant loans at great rates.</h2>
                            <h2>Earn great returns by becoming a lender on our platform.</h2>
                            </div>  
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <h2 className='first-line-frontpag'>
                            <Image src={loanimage} alt='image' sizes={100}/>
                        </h2>
                    </Col>
                </Row>
               
               
                <Row justify="center" style={{ marginTop: "5px",backgroundColor:'#EFF9F0',padding:'10px' }}>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <h2 className='first-line-frontpag'>
                            <Image src={loan} alt='image' sizes={100}/>
                        </h2>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={12}>
                        <div className=''>
                            <div style={{justifyContent:'space-around',alignItems:'center',display:'flex',top:'100px'}}>
                            <h2 style={{width:'400px',fontSize:'40px',color:'green',textAlign:'left',}}><center>Get an instant loan for your Personal or business needs</center></h2>
                            </div>  
                        </div>
                    </Col>
                   
                </Row>
                   
                </Card>
            </div>

            <Footer/>
                 
        </div>
    );
}

export default Index;
