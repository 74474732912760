
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from './components';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from './components/navbar';
import AboutUs from './components/about';
import Service from './components/service';

function App() {
  return (

         <BrowserRouter>
         <NavBar/>
          <Routes>
            <Route exact path="/" element={<Index />}></Route>
            <Route path="/about" element={<AboutUs />}></Route>
            <Route path="/service" element={<Service />}></Route>
          </Routes>
        </BrowserRouter>
  );
}

export default App;
