import React from 'react';
import { DeleteOutlined } from "@ant-design/icons";
import { Row, Col, Button, Card, Avatar, Dropdown, Table, Menu, Tag, Input,Image, message as gmessage, Popconfirm } from 'antd';
import Slider from './slider';
import vision from '../images/vision.png'
import mission from '../images/mission.svg'
import value from '../images/value.jpeg'
import focus from '../images/focus.jpg'
import abouts from '../images/about.png'
import Footer from './footer';

const AboutUs = () => {
    return (
        <div>
            <div><Image src={abouts} alt='about banner'/></div>
            <div>
                <Card>
                    <Row justify="center" style={{ marginTop: "5px",backgroundColor:'#EFF9F0',padding:'10px' }}> 
                    <Col xs={24} sm={24} md={24} lg={12}>
                            <div className='vision' style={{justifyContent:'center',display:'flex',alignItems:'center',width:'500px', padding:'30px'}}>
                               
                                    <div style={{fontSize:'20px',color:'green',textAlign:'left',}}>
                                        <h3>Vision</h3> 
                                        <h4>To provide sustainable financing solutions for individuals, 
                                            SMEs LPO Financing, Asset Financing and Invoice Discounting to boost economic growth.
                                        </h4>
                                    </div>
                                
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12}>
                            <h2 className='first-line-frontpag'>
                                <Image src={vision} alt='image' sizes={100}/>
                            </h2>
                        </Col>
                    </Row>


                <Row justify="center" style={{ marginTop: "5px",backgroundColor:'#EFF9F0',padding:'10px' }}>
                <Col xs={24} sm={24} md={24} lg={12}>

                <div className='vision' style={{justifyContent:'center',display:'flex',alignItems:'center',width:'500px', padding:'30px'}}>
                               
                               <div style={{fontSize:'20px',color:'green',textAlign:'left',}}>
                                   <h3>Mission</h3> 
                                   <h4>Our Mission is to stimulate economic empowerment for individuals and SMEs through sustainable finance solutions.
                                   </h4>
                               </div>
                           
                       </div>
                        {/* <div className=''>
                            <div style={{justifyContent:'space-around',alignItems:'center',display:'flex',top:'100px'}}>
                            <h2 style={{fontSize:'40px',color:'green',textAlign:'left',}}><center>Mission <h3>Our Mission is to stimulate economic empowerment for individuals and SMEs through sustainable finance solutions.</h3></center></h2>
                            </div>  
                        </div> */}
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <h2 className='first-line-frontpag'>
                            <Image src={mission} alt='image' sizes={100}/>
                        </h2>
                    </Col>
                </Row>


                <Row justify="center" style={{ marginTop: "5px",backgroundColor:'#EFF9F0',padding:'10px' }}>
                <Col xs={24} sm={24} md={24} lg={12}>

                <div className='vision' style={{justifyContent:'center',display:'flex',alignItems:'center',width:'500px', padding:'30px'}}>
                               
                               <div style={{fontSize:'20px',color:'green',textAlign:'left',}}>
                                   <h3> Core values </h3> 
                                   <h4>- Professionalism </h4>
                                   <h4>- Integrity </h4>
                                   <h4>- Confidentiality </h4>
                                   <h4>- Customer service </h4>
                               </div>
                           
                       </div>

                        {/* <div className=''>
                            <div style={{justifyContent:'space-around',alignItems:'center',display:'flex',top:'100px'}}>
                            <h2 style={{fontSize:'40px',color:'green',textAlign:'left',}}>
                                <center>
                                    Core values 
                                <h3>
                                - Professionalism 
                                </h3>
                                <h3>
                                - Integrity  
                                </h3>
                                <h3>
                                - Confidentiality          
                                </h3>
                                <h3>
                                - Customer service          
                                </h3>
                                </center></h2>
                            </div>  
                        </div> */}
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <h2 className='first-line-frontpag'>
                            <Image src={value} alt='image' sizes={100}/>
                        </h2>
                    </Col>
                </Row>




                <Row justify="center" style={{ marginTop: "5px",backgroundColor:'#EFF9F0',padding:'10px' }}>
                    
                    
                <Col xs={24} sm={24} md={24} lg={12}>
                        
                <div className='vision' style={{justifyContent:'center',display:'flex',alignItems:'center',width:'500px', padding:'30px'}}>
                               
                               <div style={{fontSize:'20px',color:'green',textAlign:'left',}}>
                                   <h3> Business focus </h3> 
                                   <h4>- Small and Medium Scale Enterprises </h4>
                                   <h4>- Educational institutions  </h4>
                                   <h4>- Hospitality </h4>
                                   <h4>- Cooperatives, Trade Unions and traders </h4>
                               </div>
                           
                       </div>
                        
                        {/* <div className=''>
                            <div style={{justifyContent:'space-around',alignItems:'center',display:'flex',top:'100px'}}>
                            <h2 style={{fontSize:'40px',color:'green',textAlign:'left',}}>
                                <center>
                                Business focus
                                <h3>
                                - Small and Medium Scale Enterprises 
                                </h3>
                                <h3>
                                - Educational institutions  
                                </h3>
                                <h3>
                                - Hospitality          
                                </h3>
                                <h3>
                                - Cooperatives, Trade Unions and traders         
                                </h3>
                                </center></h2>
                            </div>  
                        </div> */}
                    </Col>
                    
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <h2 className='first-line-frontpag'>
                            <Image src={focus} alt='image' sizes={100}/>
                        </h2>
                    </Col>

                    
                   
                </Row>
                   
                </Card>
            </div>

            <Footer/>
                 
        </div>
    );
}

export default AboutUs;
