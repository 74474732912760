import Carousel from 'react-bootstrap/Carousel';
import r from '../images/R.jpg';
import personalLoan from '../images/personalLoans.png';
import sme from '../images/smeloan.jpg';
import travel from '../images/travelloan.jpg';
import discount from '../images/discount2.jpg';
import backgorung from '../images/Home-Loans-Banner-Image-1210x528.jpg';

function Slider() {
  return (
    <Carousel fade>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={personalLoan}
          alt="First slide"
        />
        <Carousel.Caption>
            <div className='carousel-content'>
     
            {/* <p>Get simple instant loans with zero paperwork</p>
                <p>Are you a business looking for ways to make more money through lending??</p>
        <p>Now you can have Cover and Control!</p>
        <p>Grow your money by becoming a lender on MAPHARY Financials your best life with zero worries</p>*/}
        </div> 
          
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
        src={sme}
          alt="Second slide"
        />

        <Carousel.Caption>
        {/* <div className='carousel-content'>
          <h3>Get simple instant loans with zero paperwork.</h3>
          <p>Are you a business looking for ways to make more money through lending??</p>
          <p>Now you can have Cover and Control!</p>
          <p>Grow your money by becoming a lender on MAPHARY Financials your best life with zero worries</p>
        </div> */}
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={travel}
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>MAPHARY Financials</h3>
          <p>
            The Best Loan Platform.
          </p> */}
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item>
        <img
          className="d-block w-100"
          src={discount}
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>MAPHARY Financials</h3>
          <p>
            The Best Loan Platform.
          </p> */}
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Slider;